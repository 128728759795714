<template>
  <div class="robot-address">
    <div class="cell">
      <el-tabs @tab-click="handleQH" v-model="activeName" type="border-card">
        <el-tab-pane name="management" label="管理">
          <el-form
            size="mini"
            inline
            ref="form2"
            :model="form"
            label-width="140px"
            style="margin: 0 0 10px"
          >
            <el-form-item label="机器人买家姓名：">
              <el-input
                style="width: 160px"
                clearable
                v-model="form2.member_name"
                @clear="handleClear"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleClick">查询</el-button>
            </el-form-item>
          </el-form>

          <div
            style="
              padding: 16px;
              background: #f3f5f8;
              color: #09c;
              font-size: 13px;
            "
          >
            <span
              style="cursor: pointer"
              @click="btnType = btnType ? false : true"
            >
              操作提示 <i class="el-icon-caret-top" v-if="btnType"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </span>
          </div>
          <div style="font-size: 12px; padding: 10px" v-if="btnType">
            1、买家机器人添加后，系统会随机给卖家分配下单的买家机器人和提问题的买家客服。
            <br />
            2、下单规则根据订单生成机制出单。
          </div>
          <el-table :data="tableData.list" style="width: 100%">
            <el-table-column
              prop="member_name"
              label="机器人买家姓名"
              align="center"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="member_mobile"
              label="联系电话"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="sq_kf_count"
              label="售前出题数"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="sz_kf_count"
              label="售中出题数"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="sh_kf_count"
              label="售后出题数"
            >
            </el-table-column>

            <el-table-column align="center" prop="member_state" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.member_state == 1"
                  >启用</el-tag
                >
                <el-tag v-else type="info">停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              width="200"
              align="center"
              prop="address"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button size="mini" @click="handEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  v-if="scope.row.member_state == 0"
                  size="mini"
                  type="success"
                  @click="handleONandOFF(scope.row, 1)"
                  >启用</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="danger"
                  @click="handleONandOFF(scope.row, 0)"
                  >停用</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            small
            style="padding: 20px 10px"
            background
            :page-size="page_size"
            :current-page="page"
            @current-change="handlePage"
            layout="prev, pager, next"
            :total="tableData.total"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="add" :label="title">
          <el-form
            size="small "
            ref="form"
            :rules="rules"
            :model="form"
            label-width="140px"
            label-position="left"
            style="width: 300px"
          >
            <el-form-item prop="member_name" label="买家机器人姓名:">
              <el-input
                style="width: 300px"
                v-model="form.member_name"
                placeholder="输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="member_mobile" label="联系电话：">
              <el-input
                style="width: 300px"
                v-model="form.member_mobile"
                placeholder="输入电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="售前客服：" prop="sq_kf_ids">
              <el-input
                style="width: 300px"
                v-model="form.sq_kf_ids"
                placeholder="请选择客服问题"
                suffix-icon="el-icon-arrow-down"
                @focus="showKfList(1)"
              ></el-input>
            </el-form-item>
            <el-form-item label="售中客服：" prop="sz_kf_ids">
              <el-input
                style="width: 300px"
                v-model="form.sz_kf_ids"
                placeholder="请选择客服问题"
                suffix-icon="el-icon-arrow-down"
                @focus="showKfList(2)"
              ></el-input>
            </el-form-item>
            <el-form-item label="售后客服：" prop="sh_kf_ids">
              <el-input
                style="width: 300px"
                v-model="form.sh_kf_ids"
                placeholder="请选择客服问题"
                suffix-icon="el-icon-arrow-down"
                @focus="showKfList(3)"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
              <el-button @click="handleBlcak">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 选择客服 -->
    <el-dialog
      class="kfDialog"
      :title="kfTitle"
      :visible.sync="kfVisible"
      width="600px"
      top="10vh"
      :before-close="handleClose"
    >
      <div class="text-info">
        总共{{ cateTotal }}个，已选中{{ totalSelect }}个。
      </div>
      <el-table
        :data="cateList"
        ref="multipTable"
        @selection-change="handSelect"
        @select="rowHandSelect"
        @select-all="allHandSelect"
        :row-key="getRowKeys"
      >
        <el-table-column
          type="selection"
          align="center"
          :reserve-selection="true"
          width="55"
        >
        </el-table-column>
        <el-table-column prop="content" label="咨询问题"></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="kfSizeChange"
          @current-change="kfCurrentChange"
          :current-page="cateQuery.page"
          :page-sizes="[10, 20, 50]"
          :page-size="cateQuery.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="cateTotal"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="setkfQs">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRobotList,
  saveRobot,
  getRobotKfList,
  getRobotInfo,
  updateRobotStatus,
} from "@/api/admin.js";
export default {
  data() {
    return {
      activeName: "management",
      tableData: {
        list: [],
      },

      prodType: [
        {
          value: 1,
          name: "售前",
        },
        {
          value: 2,
          name: "售中",
        },
        {
          value: 3,
          name: "售后",
        },
      ],
      prodClass: [
        {
          value: 1,
          name: "通用类目",
        },
        {
          value: 2,
          name: "类目",
        },
      ],
      page: 1,
      page_size: 10,
      form2: {
        member_name: "",
      },
      cateQuery: {
        kf_type: 1,
        page: 1,
        page_size: 10,
      },
      form: {
        id: "0",
        member_name: "",
        member_mobile: "",
        sq_kf_ids: "",
        sz_kf_ids: "",
        sh_kf_ids: "",
      },
      rules: {
        member_name: [{ required: true, message: "必填", trigger: "blur" }],
        member_mobile: [{ required: true, message: "必填", trigger: "blur" }],
        sq_kf_ids: [{ required: true, message: "必选", trigger: "change" }],
        sz_kf_ids: [{ required: true, message: "必选", trigger: "change" }],
        sh_kf_ids: [{ required: true, message: "必选", trigger: "change" }],
      },

      btnType: true,
      title: "新增",

      cateTotal: 0,
      getRowKeys(row) {
        return row.id;
      },
      kfTitle: "",
      kfVisible: false,
      cateList: [],
      curSelectId: [],
      curCateType: 1,
      savaSelIds: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      getRobotList({
        ...this.form2,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    handleClick() {
      this.page = 1;
      this.getList();
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveRobot({
            ...this.form,
          }).then((res) => {
            if (res.code == 1) {
              this.activeName = "management";
              this.$message.success(this.title + "成功");
              if (this.title == "新增") {
                this.page = 1;
              }
              this.title = "新增";
              this.getList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(value) {
      this.form.province_code = value[0].split(",")[0];
      this.form.province = value[0].split(",")[1];
      this.form.city_code = value[1].split(",")[0];
      this.form.city = value[1].split(",")[1];
      this.form.district_code = value[2].split(",")[0];
      this.form.district = value[2].split(",")[1];
    },
    handleBlcak() {
      this.$refs.form.resetFields();
      this.value = [];
      this.activeName = "management";
      this.title = "新增";
      this.acc = false;
    },

    handleQH(e) {
      if (e.name == "management") {
        this.title = "新增";
        this.$refs.form.resetFields();
      } else {
        this.form = {
          id: "0",
          member_name: "",
          member_mobile: "",
          sq_kf_ids: "",
          sz_kf_ids: "",
          sh_kf_ids: "",
        };
        this.$refs.form.resetFields();
        this.acc = true;
      }
    },
    handleONandOFF(val, type) {
      updateRobotStatus({
        id: val.id,
        status: type,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    handlePage(val) {
      this.page = val;
      this.getList();
    },

    showKfList(type) {
      if (this.kfVisible) {
        return;
      }
      switch (type) {
        case 1:
          this.kfTitle = "售前客服咨询";
          this.curSelectId = this.form.sq_kf_ids
            ? this.form.sq_kf_ids.split(",")
            : [];
          break;
        case 2:
          this.kfTitle = "售中客服咨询";
          this.curSelectId = this.form.sz_kf_ids
            ? this.form.sz_kf_ids.split(",")
            : [];
          break;
        case 3:
          this.kfTitle = "售后客服咨询";
          this.curSelectId = this.form.sh_kf_ids
            ? this.form.sh_kf_ids.split(",")
            : [];
          break;
        default:
          break;
      }
      this.curCateType = type;
      this.cateQuery.kf_type = type;
      this.cateQuery.page = 1;
      this.kfVisible = true;
      this.getCateList();
    },
    handleClose() {
      this.curSelectId = [];
      this.savaSelIds = [];
      this.$refs.multipTable.clearSelection();
      this.kfVisible = false;
    },
    handSelect(list) {
      this.savaSelIds = list.map((item) => String(item.id));
    },
    allHandSelect(selection) {

      this.cateList.forEach((item) => {
        if (this.curSelectId.includes(item.id + "")) {
          this.curSelectId.splice(this.curSelectId.indexOf(item.id + ""), 1);
        }
      });
    },
    rowHandSelect(selection, row) {
      if (this.curSelectId.includes(row.id + "")) {
        this.curSelectId.splice(this.curSelectId.indexOf(row.id + ""), 1);
      }
    },

    kfSizeChange(val) {
      this.cateQuery.page_size = val;
      this.getCateList();
    },
    kfCurrentChange(val) {
      this.cateQuery.page = val;
      this.getCateList();
    },
    // 获取客服咨询列表
    getCateList() {
      getRobotKfList({
        ...this.cateQuery,
      }).then((res) => {
        if (res.code == 1) {
          this.cateList = res.data.list;
          this.cateTotal = res.data.total;
          let joinArr = this.cateList.filter((item) =>
            this.curSelectId.includes(item.id + "")
          );
          let unCheck = joinArr.filter(
            (item) => !this.savaSelIds.includes(item.id + "")
          );
          unCheck.forEach((item) => {
            this.$nextTick(() => {
              this.$refs.multipTable.toggleRowSelection(item);
            });
          });
        }
      });
    },
    setkfQs() {
      let ids = Array.from(
        new Set([...this.savaSelIds, ...this.curSelectId])
      ).join(",");

      switch (this.curCateType) {
        case 1:
          this.form.sq_kf_ids = ids;
          break;
        case 2:
          this.form.sz_kf_ids = ids;
          break;
        case 3:
          this.form.sh_kf_ids = ids;
          break;
        default:
          break;
      }
      this.handleClose();
    },
    // 编辑
    handEdit(row) {
      this.activeName = "add";
      this.title = "编辑";
      this.getRobotDetail(row.id);
    },

    // 获取机器人详情
    getRobotDetail(id) {
      getRobotInfo({
        id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.form = {
            id: id,
            member_name: res.data.member_name || "",
            member_mobile: res.data.member_mobile || "",
            sq_kf_ids: res.data.sq_kf_ids.join(",") || "",
            sz_kf_ids: res.data.sz_kf_ids.join(",") || "",
            sh_kf_ids: res.data.sh_kf_ids.join(",") || "",
          };
        }
      });
    },
    handleClear() {
      this.getList();
    },
  },
  computed: {
    totalSelect() {
      // let totalArr = Array.from(
      //   new Set([...this.curSelectId, ...this.savaSelIds])
      // );
      let totalArr = this.savaSelIds;
      return totalArr.length;
    },
  },
};
</script>
<style lang="less" scoped>
.robot-address {
  padding: 20px;
  .cell {
    // padding: 10px;
    background: #fff;
  }
}
</style>